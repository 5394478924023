<template>
  <div>
    <div>
      <div class="search-div">
        <div class="search-head">
          <div>
            <span>Pesquisar contatos</span>
          </div>
        </div>
        <div class="search-input">
          <b-field size="is-small">
            <b-input v-model="filter" placeholder="É possível pesquisar por Nome, CPF/CNPJ ou Telefone" icon="magnify" icon-clickable
              @icon-click="loadAsyncData" @keydown.enter.native="loadAsyncData" />
          </b-field>
        </div>
      </div>
      <b-table :data="allContacts" :bordered="false" :striped="true" :narrowed="false" :hoverable="true"
        :loading="loading" :focusable="false" :mobile-cards="true" :total="total" :per-page="perPage" backend-pagination
        paginated @page-change="onPageChange">

        <b-table-column :numeric="true" field="id" label="ID" v-slot="props">
          {{ props.row.id }}
        </b-table-column>

        <b-table-column field="name" label="Nome" v-slot="props">
          {{ props.row.name }}
        </b-table-column>

        <b-table-column field="cpf_cnpj" label="CPF/CNPJ" v-slot="props">
          {{ props.row.cpf_cnpj }}
        </b-table-column>

        <b-table-column field="phone" width="200" label="Telefone" v-slot="props">
          {{ props.row.phone }}
        </b-table-column>

        <b-table-column field="update_delete" label="" v-slot="props">
          <dropdown-tag :isDeleting="contactDeletingId === props.row.id">
            <dropdown-item-tag @click="updateContact(props.row)">Atualizar</dropdown-item-tag>
            <dropdown-item-tag v-if="canDelete" @click="searchForContact(props.row)">Excluir</dropdown-item-tag>
          </dropdown-tag>
        </b-table-column>

        <template #empty v-if="!loading">
          <div class="has-text-centered">Não há registros a serem apresentados. Adicione um contato para aparecer nesta
            lista.</div>
        </template>

      </b-table>
    </div>
    <modal-confirm-contact-delete :isComponentModalActive="isComponentModalActive" :contact="contactDeleting"
      :duplicate="duplicateContact" v-on:closeModal="closeModal" v-on:deleteContact="deleteDuplicate" />
  </div>
</template>

<script>

import DropdownTag from '../commons/DropdownTag.vue'
import DropdownItemTag from '../commons/DropdownItemTag.vue'
import ModalConfirmContactDelete from '../modals/ModalConfirmContactDelete.vue'
import { get } from '../../utils/api'

export default {
  name: 'ContactsList',
  props: {
    isAdmin: {
      type: Boolean,
      required: true
    },
    canDelete: {
      type: Boolean,
      required: true
    },
    contactDeletingId: {
      type: Number,
      required: true
    }
  },
  components: {
    DropdownTag,
    DropdownItemTag,
    ModalConfirmContactDelete
  },
  data() {
    return {
      isComponentModalActive: false,
      contactDeleting: {},
      duplicateContact: {},
      contacts: [],
      total: 0,
      loading: false,
      sortField: 'vote_count',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      page: 1,
      perPage: 20,
      filter: ''
    }
  },
  computed: {
    allContacts: {
      get() {
        return this.contacts
      },
      set() {
        // do nothing
      }
    }
  },
  created() {
    this.loadAsyncData();
  },
  methods: {
    async searchForContact(contact) {
      const duplicateContact = await get(`contacts/findContact/${contact.phone}/${contact.id}`)
      if (duplicateContact.data) {
        this.contactDeleting = contact
        this.duplicateContact = duplicateContact.data
        this.isComponentModalActive = true
        return
      }
      this.confirmContactDelete(contact)
    },
    confirmContactDelete(contact) {
      this.$buefy.dialog.confirm({
        title: 'Excluir contato',
        message: 'Todos os tickets, mensagens agendadas e pesquisas de satisfação relacionadas a este contato também serão excluídos!<br>Deseja <b>excluir</b> mesmo assim?',
        confirmText: 'Excluir contato',
        type: 'is-danger',
        hasIcon: true,
        cancelText: 'Cancelar',
        onConfirm: () => this.$emit('removeContact', contact)
      })
    },
    deleteDuplicate(contact) {
      this.closeModal()
      this.$emit('removeContact', contact)
    },
    updateContact(contact) {
      this.$emit('updateContact', contact)
    },
    closeModal() {
      this.contactDeleting = {}
      this.isComponentModalActive = false
    },
    loadAsyncData() {
      this.loading = true
      get('contacts', { page: this.page, limit: this.perPage, filter: this.filter })
        .then(({ data: { results, total, limit } }) => {
          this.total = total
          this.contacts = results
          this.loading = false
        })
        .catch((error) => {
          this.contacts = []
          this.total = 0
          this.loading = false
          throw error
        })
    },
    onPageChange(page) {
      this.page = page
      this.loadAsyncData()
    },
    type(value) {
      const number = parseFloat(value)
      if (number < 6) {
        return 'is-danger'
      } else if (number >= 6 && number < 8) {
        return 'is-warning'
      } else if (number >= 8) {
        return 'is-success'
      }
    }
  }
}
</script>

<style lang="scss">
.search-div {
  border-bottom: 1px solid #e1e4e8;
  box-shadow: 0px 1px 5px #e1e4e8;
}

.search-head {
  border-bottom: 1px solid #e1e4e8;
  height: 64px;
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
}

.search-head div {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 0em 1em;
}

.search-head div span:first-child {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  color: var(--chat-header-color-name);
}

.search-head div button {
  margin-left: auto;
  display: flex;
  align-items: center;
  color: #8f8f8f;
  cursor: pointer;
  border: 0px;
  .icon {
    font-size: 20px
  }
}

@media only screen and (max-width: 768px) {
  .search-head {
    height: 50px;
  }
}

.search-input {
  padding: 0.5em
}
</style>

